function bankValidator(){
	this.api = new apiInterface();
	var that = this;
	this.validateAll = function(callback){
		// validate title
		var validation = [
			function(next) { that.sortCode(
				$('#lead_script #sort-code-1').val(),
				$('#lead_script #sort-code-2').val(),
				$('#lead_script #sort-code-3').val()
				,next); },
			function(next) { that.accountNumber($('#lead_script #account-number').val(),next); },
			function(next) { that.validateName($('#lead_script #account-name').val(),next); },
			function(next) { that.validateDay($('#lead_script #payment-date').val(),next); }
		];
		this.processSeries(validation,function(results){
			var error_count = 0;
			var errors = [];
			var data = [];
			for(var i = 0; i<results.length;i++){
				if(results[i][0].status === 'error'){
					errors.push(results[i][0].error);
					error_count++;
				}
				else{
					data.push(results[i][0].data);
				}
			}
			if(error_count===0){
				callback(null,data);	
			}
			else{
				callback(errors,data);
			}
		});
	},

	this.validateData = function(data, callback = function(){}){
		var validation = [];
		var that = this;
		$.each(data, function(key, value){
			var method_name = toCamelCase(key);
			if(isset(that[method_name])){
				validation.push(function(next){
					if(method_name == 'sortCode' && value.length == 3){
						that[method_name](
							value[0],
							value[1],
							value[2],
							next
						);
					}
					else{
						that[method_name](value,next);
					}
				})
			}
		});	
		this.processSeries(validation,function(results){
			var error_count = 0;
			var errors = [];
			var data = [];
			for(var i = 0; i<results.length;i++){
				if(results[i][0].status === 'error'){
					errors.push(results[i][0].error);
					error_count++;
				}
				else{
					data.push(results[i][0].data);
				}
			}

			if(error_count===0){
				callback(null,data);	
			}
			else{
				callback(errors,data);
			}
		});
	}

	this.accountName = function(name, callback){
		return this.validateName(name, callback);
	}

	this.validateName = function(name,callback){
		if(name.length > 18){
			callback({
				status:'error',
				error: { 
					message:'Account Name too long - Max length = 18 charactors',
					target:'account-name-error'
				}
			});
		}
		else{
			if(name.length >2){
				callback({
					status:'ok',
					data : {
						field : 'account-name',
						value : name
					}
				});
			}
			else{
				callback({
					status:'error',
					error: { 
						message:'Please enter a valid account names',
						target:'account-name-error'
					}
				});
			}
		}
	},
	this.sortCode = function(part1,part2,part3,callback){
		if(part1.length == 2 && part2.length == 2 && part3.length == 2){
			callback({
					status:'ok',
					data : {
						field : 'sort-code',
						value : part1+'-'+part2+'-'+part3
					}
			});
		}else{
			callback({
				status:'error',
				error: { 
					message:'Please enter a valid sort code',
					target:'sort-code-error'
				}
			});
		}
	},
	this.accountNumber = function(account,callback){
		if(account.length >= 6 && account.length <= 8){
			callback({
				status:'ok',
				data : {
					field : 'account-number',
					value : account
				}
			});
		}
		else{
			callback({
				status:'error',
				error: {
					message:'Please enter a valid account number',
					target:'account-number-error'
				}
			});
		}	
	},
	this.validateDay = function(day,callback){
		if(isset(day)){
			if(day >=1 && day <= 31){
				callback({
					status:'ok',
					data: {
						field : 'payment-day',
						value : day
					}
				});
			}
			else{
				callback({
					status:'error',
					error: {
						message:'Please enter a valid payment date',
						target:'payment-date-error'
					}
				});
			}
		}
		else{
			callback({
				status:'ok',
				data: {
					field : 'payment-day',
					value : null
				}
			});
		}
	},
	this.processSeries = function(callbacks, last) {
		var results = [];
		function next() {
	    var callback = callbacks.shift();
		if(callback) {
			callback(function() {
				results.push(Array.prototype.slice.call(arguments));
				next();
			});
		} 
		else {
			last(results);
		}
	}
	next();
	};
}


module.exports = bankValidator;